
<template>
  <vs-list>
    <vs-list-header title="People Group" color="primary"></vs-list-header>

    <draggable :list="list">
      <transition-group>
        <vs-list-item class="list-item" v-for="listItem in list" :key="listItem.email" :title="listItem.name" :subtitle="listItem.email">
          <vs-avatar slot="avatar" :text="listItem.name" />
        </vs-list-item>
      </transition-group>
    </draggable>

  </vs-list>
</template>
<script>
import draggable from 'vuedraggable'

export default {
  data() {
    return {
      list: [{
          name: "Paz Joya",
          email: "girliness@spotlike.co.uk",
        },
        {
          name: "Sunshine Cose",
          email: "executrixship@equisized.edu",
        },
        {
          name: "Alba Dobbin",
          email: "bidding@demibob.or",
        },
        {
          name: "Marlin Hinchee",
          email: "preholding@scuffly.co.uk",
        },
        {
          name: "Leia Atienza",
          email: "unmeasurableness@interlamellar.co.uk"
        },
        {
          name: "Lashawna Vaudrainm",
          email: "soaking@khubber.com"
        },
        {
          name: "Liliana Henscheid",
          email: "lecideine@turndown.org"
        },
        {
          name: "Keven Kolter",
          email: "nontenure@anglicanum.co.uk"
        }
      ],
    }
  },
  components: {
    draggable
  }
}
</script>
<style>
.list-item {
  transition: all 1s
}
</style>
